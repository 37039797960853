import React, { useEffect, useState, ChangeEvent } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AuthUser } from "aws-amplify/auth";
import { Menu, PlusCircle } from "react-feather";

import { Asset, AlbumInfo } from "../shared/types";

import AlbumMenuModal from "./modals/AlbumMenuModal";
import ProgressModal from "./modals/ProgressModal";
import ScrollToTop from "./ScrollToTop";

import { getAlbum, uploadAssets, getImages } from "../shared/utils";

import Lightbox from "yet-another-react-lightbox";
import Download from "yet-another-react-lightbox/plugins/download";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

import "yet-another-react-lightbox/styles.css";

interface AlbumProps {
  user: AuthUser;
}

const Album: React.FC<AlbumProps> = ({ user }) => {
  const { albumId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [albumInfo, setAlbumInfo] = useState<AlbumInfo | undefined>(undefined);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [showUploadCompleteNotification, setShowUploadCompleteNotification] =
    useState(false);
  const [assets, setAssets] = useState<Asset[]>([]);
  const [showLightbox, setShowLightbox] = useState<boolean>(false);
  const [lightboxIndex, setLightboxIndex] = useState<number>(0);

  // Fetch album info
  useEffect(() => {
    const fetchAlbumInfo = async () => {
      if (albumId) {
        const data = await getAlbum(albumId);
        setAlbumInfo(data);
      }
    };

    fetchAlbumInfo();
  }, [albumId]);

  useEffect(() => {
    const fethAssets = async () => {
      if (albumId) {
        setIsFetchingData(true);
        const data = await getImages(albumId);
        setAssets(data);
        setIsFetchingData(false);
      }
    };
    fethAssets();
  }, [albumId]);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    // Handle the selected files, e.g., upload to server or update state

    try {
      setIsUploading(true);

      await uploadAssets(
        files as FileList,
        albumId as string,
        setUploadProgress
      );

      setShowUploadCompleteNotification(true);

      // Show success notification
      setTimeout(() => {
        setShowUploadCompleteNotification(false);
      }, 2000);
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
    }
  };

  const handleImageClick = (index: number) => {
    setLightboxIndex(index);
    setShowLightbox(true);
  };

  const renderImages = () => {
    if (isFetchingData) {
      return (
        <div className="flex items-center justify-center ">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-r-2 border-b-2 border-teal-600"></div>
        </div>
      );
    }

    return (
      <div className="grid grid-cols-2 md:grid-cols-4 2xl:grid-cols-5 gap-4">
        {assets.map((asset, index) => (
          <div key={asset.sk}>
            <img
              className="w-full h-36 md:h-40 lg:h-48 xl:h-60 2xl:h-72 object-cover rounded-lg cursor-pointer"
              src={asset.small_uri}
              alt=""
              onClick={() => handleImageClick(index)}
            />
          </div>
        ))}
      </div>
    );
  };

  const getSrcList = () => {
    if (assets.length > 0) {
      return assets.map((asset) => ({ src: asset.full_uri }));
    }
  };

  return (
    <div className="flex flex-col p-4">
      <ScrollToTop />

      <div className="flex justify-between items-center mb-4">
        <button
          className="text-gray-600 hover:text-gray-900"
          onClick={openModal}
        >
          <Menu size={24} />
        </button>
        <button className="text-gray-600 hover:text-gray-900">
          <label className="text-gray-600 hover:text-gray-900 cursor-pointer">
            <input
              type="file"
              hidden
              multiple
              accept="image/*"
              onChange={handleFileChange}
            />
            <PlusCircle size={32} />
          </label>
        </button>
      </div>
      {renderImages()}
      {isUploading && <ProgressModal progress={uploadProgress} />}
      {showUploadCompleteNotification && (
        <div className="fixed top-0 right-0 mt-2 mr-2 p-2 bg-green-600 text-white rounded animate-slide-in">
          <p className="text-base font-semibold">Upload Complete!</p>
        </div>
      )}
      <Lightbox
        open={showLightbox}
        close={() => setShowLightbox(false)}
        slides={getSrcList()}
        index={lightboxIndex}
        plugins={[Download, Zoom]}
      />

      <AlbumMenuModal
        showModal={showModal}
        closeModal={closeModal}
        albumInfo={albumInfo as AlbumInfo}
      />
    </div>
  );
};

export default Album;
