// src/components/Modal.tsx
import React, { useState, useEffect, ChangeEvent, SetStateAction } from "react";

import { createAlbum } from "../../shared/utils";
import { Album } from "../../shared/types";

interface ModalProps {
  showModal: boolean;
  closeModal: () => void;
  setAlbums: React.Dispatch<SetStateAction<Album[]>>;
}

const CreateAlbumModal: React.FC<ModalProps> = ({
  showModal,
  closeModal,
  setAlbums,
}) => {
  const [title, setTitle] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Close modal when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const modalContainer = document.getElementById("modal-container");

      if (modalContainer && event.target == modalContainer) {
        closeModal();
        setTitle("");
      }
    };

    if (showModal) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showModal, closeModal]);

  const handleCreate = async () => {
    setIsLoading(true);
    // VALIDATE THE TITLE!!!!
    const data = await createAlbum(title);
    if (data) {
      setAlbums((oldArray): Album[] => [data, ...oldArray]);
    }

    // Close the modal
    closeModal();
    setIsLoading(false);
    setTitle("");
  };

  return (
    <div
      id="modal-container"
      className={`fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center ${
        showModal ? "" : "hidden"
      }`}
    >
      <div className="bg-white p-6 rounded shadow-md flex flex-col w-72 md:w-96">
        <h2 className="text-lg font-semibold mb-4">Album Title</h2>
        <input
          type="text"
          placeholder="Title"
          className="w-full p-2 border rounded mb-4"
          value={title}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setTitle(e.target.value)
          }
        />
        {isLoading ? (
          // Render a spinner or loader when isLoading is true
          <div className="flex items-center justify-center">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-r-2 border-b-2 border-teal-600"></div>
          </div>
        ) : (
          // Render the button when isLoading is false
          <button
            className="bg-teal-600 text-white px-4 py-2 rounded hover:bg-teal-700 mx-auto"
            onClick={handleCreate}
          >
            Create
          </button>
        )}
      </div>
    </div>
  );
};

export default CreateAlbumModal;
