// const awsconfig = {
//   aws_cognito_identity_pool_id:
//     "eu-north-1:ebd61179-0417-4672-9179-5e51a7520df8",
//   aws_cognito_region: "eu-north-1",
//   aws_user_pools_id: "eu-north-1_MILQ93xc4",
//   aws_user_pools_web_client_id: "3pq1oep0r7s0q8v5nkbk75vchj",
// };

// // export default awsconfig;
// import { Amplify, Auth, Storage } from "aws-amplify";

// {
// Auth: {
//   identityPoolId: "eu-north-1:ebd61179-0417-4672-9179-5e51a7520df8", //REQUIRED - Amazon Cognito Identity Pool ID
//   region: "eu-north-1", // REQUIRED - Amazon Cognito Region
//   userPoolId: "eu-north-1_MILQ93xc", //OPTIONAL - Amazon Cognito User Pool ID
//   userPoolWebClientId: "3pq1oep0r7s0q8v5nkbk75vchj", //OPTIONAL - Amazon Cognito Web Client ID
// },
//   Storage: {
//     AWSS3: {
//       bucket: "albums-backend-imagebucket-74m2hcx5m2ez", //REQUIRED -  Amazon S3 bucket name
//       region: "eu-north-1", //OPTIONAL -  Amazon service region
//     },
//   },
// }

const awsconfig = {
  Auth: {
    Cognito: {
      identityPoolId: "eu-north-1:ebd61179-0417-4672-9179-5e51a7520df8",
      userPoolId: "eu-north-1_MILQ93xc4",
      userPoolClientId: "3pq1oep0r7s0q8v5nkbk75vchj",
    },
  },
  Storage: {
    S3: {
      bucket: "imagebucket-albums-backend", //REQUIRED -  Amazon S3 bucket name
      region: "eu-north-1", //OPTIONAL -  Amazon service region
    },
  },
};

export default awsconfig;
