import { fetchAuthSession } from "aws-amplify/auth";

import { uploadData } from "aws-amplify/storage";
import { ulid } from "ulid";

import { Album } from "./types";

const API_URL = "https://4wpa78z43m.execute-api.eu-north-1.amazonaws.com/prod";

const getCredentials = async () => {
  const session = await fetchAuthSession();
  return session;
};

export const getAlbums = async () => {
  try {
    const session = await getCredentials();
    const response = await fetch(`${API_URL}/albums`, {
      method: "GET",
      headers: {
        Authorization: `${session.tokens?.idToken?.toString()}`,
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const getAlbum = async (albumId: string) => {
  try {
    const session = await getCredentials();
    const response = await fetch(`${API_URL}/album/${albumId}`, {
      method: "GET",
      headers: {
        Authorization: `${session.tokens?.idToken?.toString()}`,
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const addUserToAlbum = async (albumId: string) => {
  try {
    const session = await getCredentials();
    const response = await fetch(`${API_URL}/album/${albumId}/members`, {
      method: "POST",
      headers: {
        Authorization: `${session.tokens?.idToken?.toString()}`,
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const createAlbum = async (
  title: string
): Promise<Album | undefined> => {
  try {
    const session = await getCredentials();
    const response = await fetch(`${API_URL}/albums`, {
      method: "POST",
      headers: {
        Authorization: `${session.tokens?.idToken?.toString()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ title }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const getImages = async (albumId: string) => {
  try {
    const session = await getCredentials();
    const response = await fetch(`${API_URL}/album/${albumId}/images`, {
      method: "GET",
      headers: {
        Authorization: `${session.tokens?.idToken?.toString()}`,
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const uploadAssets = async (
  assets: FileList,
  albumId: string,
  progressCallback?: (progress: number) => void
) => {
  const session = await getCredentials();
  const userSub = session.userSub;

  const numAssets = assets.length;
  let uploadedAssets = 0;

  let progress;

  for (let index = 0; index < assets.length; index++) {
    progress = uploadedAssets / numAssets;
    const asset = assets[index];

    const fileExtension = asset.name.split(".").pop();
    const assetId = ulid(asset.lastModified);

    try {
      await uploadData({
        key: `${userSub}/${albumId}/${assetId}.${fileExtension}`,
        data: asset,
      }).result;
      uploadedAssets++;

      progress = Math.round((uploadedAssets / numAssets) * 100);

      if (progressCallback) {
        progressCallback(progress);
      }
    } catch (error) {
      console.log("ERROR : ", error);
    }
  }
};
