import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AlbumInfo } from "../shared/types";

import { getAlbum, addUserToAlbum } from "../shared/utils";

import ScrollToTop from "./ScrollToTop";

const Collaborate: React.FC = () => {
  const navigate = useNavigate();
  const { albumId } = useParams();
  const [albumInfo, setAlbumInfo] = useState<AlbumInfo | undefined>(undefined);
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Fetch album info
  useEffect(() => {
    const fetchAlbumInfo = async () => {
      if (albumId) {
        setIsFetchingData(true);
        const data = await getAlbum(albumId);
        setAlbumInfo(data);
        setIsFetchingData(false);
      }
    };

    fetchAlbumInfo();
  }, [albumId]);

  const handleJoinClick = async () => {
    // Join album
    console.log("Joining album");

    if (albumId) {
      setIsLoading(true);
      const data = await addUserToAlbum(albumId);
      console.log(data);
      if (data["statusCode"] === 201) {
        // accepted
        // navigate and replace to home
        navigate(`/`, { replace: true });
      } else {
        console.error("Could not add user to album");
      }
      setIsLoading(false);
    }
  };

  const renderContent = () => {
    if (isFetchingData) {
      return (
        <div className="flex items-center justify-center ">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-r-2 border-b-2 border-teal-600"></div>
        </div>
      );
    }

    return (
      <>
        <h2 className="text-lg font-semibold mb-2">You've been invited!</h2>
        <p className="text-base mb-2">
          You've been invited to collaborate on an Album!
        </p>
        <div
          className="bg-slate-100 border rounded overflow-hidden shadow-lg flex flex-col cursor-pointer"
          onClick={() => console.log("hello")}
        >
          <img src={albumInfo?.thumbnail} alt={albumInfo?.title} className="" />

          <h2 className="text-md text-center my-2 px-2 text-black">
            {albumInfo?.title}
          </h2>
        </div>
        {isLoading ? (
          <div className="flex items-center justify-center ">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-r-2 border-b-2 border-teal-600"></div>
          </div>
        ) : (
          <button
            className="flex items-center bg-green-600 text-white px-4 py-2 mt-5 rounded hover:bg-green-700 mx-auto"
            onClick={handleJoinClick}
            disabled={isLoading}
          >
            Join Album!
          </button>
        )}
      </>
    );
  };

  return (
    <div className="bg-white p-8 flex flex-col w-5/6 md:w-3/5 lg:w-2/5 mx-auto">
      <ScrollToTop />
      {renderContent()}
    </div>
  );
};

export default Collaborate;
