import { Routes, Route, HashRouter } from "react-router-dom";

import { Amplify } from "aws-amplify";
import { AuthUser } from "aws-amplify/auth";

import { Authenticator } from "@aws-amplify/ui-react";

import AlbumList from "./components/AlbumList";
import Album from "./components/Album";
import Collaborate from "./components/Collaborate";

import "@aws-amplify/ui-react/styles.css";
import "./styles/tailwind.css";

import awsconfig from "./aws-exports.js";

Amplify.configure(awsconfig);

const App = () => {
  return (
    <Authenticator loginMechanisms={["email"]}>
      {({ signOut, user }) => (
        <div className="bg-amber-50 min-h-screen p-4 overflow-y-auto">
          <HashRouter>
            <Routes>
              <Route path="/" element={<AlbumList />} />
              <Route
                path="album/:albumId"
                element={<Album user={user as AuthUser} />}
              />
              <Route path="collaborate/:albumId" element={<Collaborate />} />
            </Routes>
          </HashRouter>
        </div>
      )}
    </Authenticator>
  );
};

export default App;
