// src/components/Modal.tsx
import React, { useState, useEffect, ChangeEvent } from "react";

import { Link } from "react-feather";

import { Album } from "../../shared/types";

interface ModalProps {
  showModal: boolean;
  closeModal: () => void;
  albumInfo: Album;
}

const AlbumMenuModal: React.FC<ModalProps> = ({
  showModal,
  closeModal,
  albumInfo,
}) => {
  const [deleteText, setDeleteText] = useState<string>("");
  const [showNotification, setShowNotification] = useState(false);

  const [isLoading, setIsLoading] = useState<boolean>(false); // used for deleting albums

  // Close modal when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const modalContainer = document.getElementById("modal-container");

      if (modalContainer && event.target === modalContainer) {
        closeModal();
        setDeleteText("");
      }
    };

    if (showModal) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showModal, closeModal]);

  const handleGetUrlClick = () => {
    const url = `${window.location.origin}/#/collaborate/${albumInfo.id}`; // Replace with your actual album URL
    // Use the Clipboard API
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setShowNotification(true);

        // Hide the notification after 3 seconds (adjust as needed)
        setTimeout(() => {
          setShowNotification(false);
        }, 3000);
      })
      .catch((error) => {
        console.error("Unable to copy to clipboard", error);
      });
  };

  const renderAlbumHeader = () => {
    return (
      <div className="bg-white p-6 flex flex-col">
        <h2 className="text-lg font-semibold mb-0">{albumInfo?.title}</h2>
      </div>
    );
  };

  const renderShareAlbum = () => {
    return (
      <div className="bg-white p-6 flex flex-col">
        <h2 className="text-base md:text-lg font-semibold mb-0">Share Album</h2>
        <h2 className="text-xs md:text-sm mb-2">
          Share URL with others to collaborate on an album
        </h2>
        <button
          className="flex items-center bg-teal-500 text-white px-4 py-2 rounded hover:bg-teal-600 mx-auto"
          onClick={handleGetUrlClick}
        >
          <p className="pr-2 text-xs md:text-base">Get URL</p>
          <Link size={16} />
        </button>
      </div>
    );
  };

  const renderAlbumInfo = () => {
    return (
      <div className="bg-white p-6 flex flex-col">
        <h2 className="text-base md:text-lg font-semibold mb-0">Album Info</h2>

        <p className="text-sm mb-4 mb-0">
          Created by: {albumInfo?.owner_email}
        </p>
        <p className="text-sm mb-4 mb-0">Created: {albumInfo?.created_at}</p>

        {/* Add list of members here */}
      </div>
    );
  };

  const renderDeleteAlbum = () => {
    return (
      <div className="bg-white p-6 flex flex-col">
        <h2 className="text-base md:text-lg font-semibold mb-2">
          Delete Album
        </h2>
        <h2 className="text-xs md:text-sm mb-1">
          Write "delete" to delete album
        </h2>
        <input
          type="text"
          placeholder="delete"
          className="w-full p-2 border rounded mb-4 text-xs md:text-base"
          value={deleteText}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setDeleteText(e.target.value)
          }
        />
        {isLoading ? (
          // Render a spinner or loader when isLoading is true
          <div className="flex items-center justify-center">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-r-2 border-b-2 border-teal-600"></div>
          </div>
        ) : (
          // Render the button when isLoading is false
          <button
            className={`bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 mx-auto ${
              deleteText !== "delete" ? "opacity-25" : ""
            }`}
            onClick={() => alert("I have not added delete functionality yet..")}
            disabled={deleteText !== "delete"} // Disable the button if deleteText is not "delete"
          >
            <p className="text-xs md:text-base">Delete album</p>
          </button>
        )}
      </div>
    );
  };

  return (
    <div
      id="modal-container"
      className={`fixed overflow-y-auto inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center ${
        showModal ? "" : "hidden"
      }`}
    >
      <div className="flex flex-col shadow-lg rounded w-72 md:w-96 divide-y">
        {renderAlbumHeader()}
        {renderShareAlbum()}
        {renderAlbumInfo()}
        {renderDeleteAlbum()}
      </div>
      {showNotification && (
        <div className="absolute top-0 right-0 mt-2 mr-2 p-2 bg-green-600 text-white rounded animate-slide-in">
          Shareable URL copied to clipboard
        </div>
      )}
    </div>
  );
};

export default AlbumMenuModal;
