import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Album } from "../shared/types";

import { PlusCircle } from "react-feather";

import { getAlbums } from "../shared/utils";

import CreateAlbumModal from "./modals/CreateAlbumModal";
import ScrollToTop from "./ScrollToTop";

const AlbumList: React.FC = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [albums, setAlbums] = useState<Album[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchAlbums = async () => {
      setIsLoading(true);

      const data = await getAlbums();

      setAlbums(data);
      setIsLoading(false);
    };

    fetchAlbums();
  }, []);

  const renderAlbums = (): JSX.Element => {
    if (isLoading) {
      return (
        <div className="flex items-center justify-center ">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-r-2 border-b-2 border-teal-600"></div>
        </div>
      );
    }
    if (albums && albums.length === 0) {
      return <p>No albums found.</p>;
    }

    return (
      <div className="grid grid-cols-2 md:grid-cols-4 2xl:grid-cols-5 gap-4">
        {albums &&
          albums.map((album) => (
            <div
              key={album.id}
              className="bg-slate-100 border rounded overflow-hidden shadow-lg flex flex-col cursor-pointer"
              onClick={() => navigate(`/album/${album.id}`)}
            >
              <img
                className="w-full h-36 md:h-40 lg:h-48 xl:h-60 2xl:h-72 object-cover"
                src={album.thumbnail}
                alt={album.title}
              />

              <h2 className="text-md text-center my-2 px-2 text-black">
                {album.title}
              </h2>
            </div>
          ))}
      </div>
    );
  };

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  return (
    <div className="p-4">
      <ScrollToTop />
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-3xl font-bold mb-4">Albums</h1>
        <button
          className="text-gray-600 hover:text-gray-900"
          // className="bg-cyan-500 text-white px-2 py-1 rounded hover:bg-teal-700"
          onClick={openModal}
        >
          <PlusCircle size={32} />
        </button>
      </div>
      {renderAlbums()}

      <CreateAlbumModal
        showModal={showModal}
        closeModal={closeModal}
        setAlbums={setAlbums}
      />
    </div>
  );
};

export default AlbumList;
