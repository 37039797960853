import React from "react";

interface ProgressModalProps {
  progress: number;
}

const ProgressModal: React.FC<ProgressModalProps> = ({ progress }) => {
  return (
    <div className="fixed top-0 left-0 w-full bg-gray-400 text-white p-2 text-center">
      <p className="text-lg font-semibold">Uploading...</p>
      <progress
        className="w-full h-2 bg-white rounded-full"
        value={progress}
        max="100"
      />
      <p className="text-sm">{progress}%</p>
    </div>
  );
};

export default ProgressModal;
